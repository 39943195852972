import CookieWall from '../components/cookie-wall';

export default class CookieMessage {
  constructor(el) {
    this.cookieWall = CookieWall.getInstance();

    if (!this.cookieWall.isAccepted()) {
      this.el = el;
      this.init(el);
    }
  }

  init(el) {
    // Setup message and click
    const accept = (e) => {
      if (e.target.tagName !== 'A') {
        this.accept();
        e.stopPropagation();
        e.preventDefault();
      }
    };

    el.addEventListener('click', accept);
    el.classList.add('cookie-message--visible');

    this.cookieWall.on('cookies-accepted', () => {
      this.hide();
    });
  }

  hide() {
    this.el.classList.remove('cookie-message--visible');
  }

  accept() {
    this.cookieWall.accept();
  }
}
