/**
 * hamburgah menu!
 *
 *
 *
 * Created by MKR 02/01/2018
 * (c) Fabrique - Merken, Design & Interactie
 */

export default class HamburgerController {

  constructor(element) {
    this.element = element;

    const hamburgerMenu = document.querySelector('[data-role="hamburger-menu"]');
    const closeHamburgerMenu = document.querySelector('[data-role="hamburger-closer"]');
    const bodyElement = document.querySelector('body');

    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      hamburgerMenu.classList.add('active');
      bodyElement.classList.add('navigation-active');

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          hamburgerMenu.style.height = `${window.innerHeight}px`;
        });
      });
    });

    closeHamburgerMenu.addEventListener('click', (event) => {
      event.preventDefault();
      hamburgerMenu.classList.remove('active');
      bodyElement.classList.remove('navigation-active');
    });

    window.addEventListener('resize', () => {
      if (hamburgerMenu.classList.contains('active')) {
        hamburgerMenu.style.height = `${window.innerHeight}px`;
      }
    });

    // hamburgerMenu.classList.remove('inactive')

  }

}
