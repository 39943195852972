/**
 * listener on a wide card to expand it
 *
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */


export default class WideCardController {

  constructor(element) {
    this.element = element; // the container

    let openedLi = document.querySelector('.teso-card-row-row--open');
    if (openedLi.classList.contains('teso-card-row-row--scroll')) {
      openedLi.scrollIntoView({ behavior: 'smooth' });
    }

    let elHeight = openedLi.offsetHeight;
    openedLi.style.height = elHeight + 'px';

  }

}
