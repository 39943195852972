/**
 * Video player
 *
 * Based on code by EKL for blue mountain
 *
 * Created by LMO on 18/01/17.
 * (c) Fabrique - Merken, Design & Interactie
 */
import { insertScript } from '../libs/fabrique/insert-script';
import { randomHash } from '../libs/fabrique/random-hash';

export default class VideoController {
  constructor(element) {
    this.element = element;
    this.id = `video-${randomHash()}`;

    const playElement = element.querySelector('[data-role="play-video"]');
    this.frameElement = element.querySelector('[data-role="video-frame"]');
    this.parentElement = element.querySelector('[data-role="video-iframe-parent"]');
    this.videoId = this.frameElement.getAttribute('data-id');
    this.autoplay = this.frameElement.getAttribute('data-autoplay') !== null;

    playElement.addEventListener('click', (event) => {
      event.preventDefault();
      playElement.classList.add('is-pressed');
      this.loadIframe();
    });
  }

  loadIframe() {
    const videoType = this.frameElement.getAttribute('data-type');

    if (videoType === 'vimeo') {
      insertScript('vimeo-api-script', 'https://player.vimeo.com/api/player.js', () => {
        const iframe = document.createElement('iframe');
        iframe.id = this.id;
        iframe.className = 'video-block__player is-hidden';
        this.parentElement.replaceChild(iframe, this.frameElement);
        this.frameElement = iframe;

        this.prepareVimeo();
      });
    } else {
      insertScript('youtube-api-script', 'https://youtube.com/iframe_api', () => {
        const div = document.createElement('div');

        div.id = this.id;
        div.className = 'video-block__player is-hidden';
        this.parentElement.replaceChild(div, this.frameElement);
        this.frameElement = div;

        this.prepareYouTube();
      });
    }
  }

  prepareVimeo() {
    const iframe = this.frameElement;
    const onPlayerReady = () => {
      let timeout;

      if (!iframe) {
        console.log('Vimeo Error: couldn\'t find Iframe.'); /* eslint-disable-line no-console */
        return;
      }

      const player = new window.Vimeo.Player(iframe);

      if (!player) {
        return;
      }

      player.setAutopause(true);
      player.setColor('#ffffff');
      player.setLoop(false);

      if (this.autoplay) {
        player.setVolume(0);
        player.play();

        // Extra call to mute in Safari -- dirty
        try {
          iframe.contentWindow.postMessage(JSON.stringify({ method: 'setVolume', value: 0 }), iframe.getAttribute('src').split(';')[0]);
        } catch (e) {
          //
        }

        // And another few extra calls to mute in Safari -- dirtier
        const muteVideo = () => player.setVolume(0);

        [100, 200, 500, 1000].map((i) => {
          window.clearTimeout(timeout);
          timeout = window.setTimeout(muteVideo, i);
          return true;
        });

        // WHY DONT YOU JUST MUTE SAFARI 😤
      }

      iframe.classList.remove('is-hidden');
      this.element.classList.add('is-playing');
      setTimeout(() => {
        this.element.classList.add('is-playing-1s');
      }, 800);
    };

    iframe.addEventListener('load', onPlayerReady);

    iframe.setAttribute('src', `//player.vimeo.com/video/${this.videoId}`);
  }

  prepareYouTube() {
    if (!this.videoId) {
      return;
    }

    const onPlayerReady = (event) => {
      const instance = event.target;

      if (!instance) {
        return;
      }

      const iframe = instance.getIframe();
      this.frameElement = iframe;

      if (!iframe) {
        console.log('Loading YouTube failed: couldn\'t find iFrame'); /* eslint-disable-line no-console */
        return;
      }

      iframe.classList.add('video__player');
      instance.setPlaybackQuality('hd1080');

      if (this.autoplay) {
        instance.mute();
      }

      instance.playVideo();
      iframe.classList.remove('is-hidden');
      this.element.classList.add('is-playing');
      setTimeout(() => {
        this.element.classList.add('is-playing-1s');
      }, 800);
    };

    window.onYouTubeIframeAPIReady = () => {
      window.youtubeScriptLoaded = true;
    };

    const interval = window.setInterval(() => {

      if (!window.youtubeScriptLoaded || !window.YT || !window.YT.Player) {
        return;
      }

      const player = new window.YT.Player(this.id, { // eslint-disable-line no-unused-vars
        width: 640,
        height: 360,
        videoId: this.videoId,
        playerVars: {
          autoplay: 1,
          hd: 1,
          showinfo: 0,
          modestbranding: 1,
          iv_load_policy: 3,
          rel: 0,
          playlist: '',
        },
        events: { onReady: onPlayerReady },
      });
      window.clearInterval(interval);
    }, 250);
  }
}
