import Swiper from 'swiper';

export default class SwiperController {

  constructor(element) {
    this.element = element;
    this.container = this.element.querySelector('.swiper-container');
    this.wrapper = this.element.querySelector('.swiper-wrapper');
    this.buttonPrev = this.element.querySelector('.swiper-button--prev');
    this.buttonNext = this.element.querySelector('.swiper-button--next');
    this.pagination = this.element.querySelector('.swiper-pagination');

    this.initSwiper();
  }

  initSwiper() {

    //check screen width, below 500px, 1 slide in view
    let slidesPerView = 2;
    if (window.innerWidth < 500) {
      slidesPerView = 1;
    }

    this.instance = new Swiper(this.container, {
      autoPlay: false,
      controlBy: 'container',
      direction: 'horizontal',
      effect: 'slide',
      grabCursor: false,
      initialSlide: 0,
      loop: true,
      nextButton: this.buttonNext,
      // pagination: this.pagination,
      pagination: {
        el: this.pagination,
        clickable: false,
      },
      paginationClickable: true,
      paginationElement: 'span',
      prevButton: this.buttonPrev,
      resistanceRatio: 0,
      roundLengths: true, // Prevents dragging fuzzyness
      setWrapperSize: true, // Fix for Firefox / IE11 for flex icm. padding-bottom
      slidesPerView: slidesPerView,
      spaceBetween: 0,
      touchRatio: 1,
      keyboardControl: true,
    });

  }

}

