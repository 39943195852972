import Cookies from 'js-cookie';


export default class NotificationController {
  constructor(element) {
    this.element = element;
    const closeElement = element.querySelector('[data-role="close-notification"]');
    const openElement = element.querySelector('[data-role="open-notification"]');
    this.message_id = closeElement.getAttribute('data-message-id')
    closeElement.addEventListener('click', (event) => {
      event.preventDefault();
      closeElement.classList.add('is-pressed');
      this.close();
    });
    openElement.addEventListener('click', () => {
      this.element.classList.remove('notification-block--disable');
    });
  }

  close() {
    this.element.classList.add('notification-block--disable');
    const inOneHour = new Date(new Date().getTime() + (60 * 60 * 1000));
    Cookies.set('teso_messages_seen_id', this.message_id, {
      expires: inOneHour,
    });
  }
}
/* { expires: 365 } */
