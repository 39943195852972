/**
 * listener on a wide card to expand it
 *
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */


export default class WideCardController {

  constructor(element) {
    this.element = element; // the card container
    let classname = 'teso-card-row-row--open';
    this.element.addEventListener('click', (event) => {

      let parentLi = element.parentNode.parentNode.parentNode.parentNode;

      if (parentLi.classList.contains(classname)) {
        parentLi.style.height ='';
        parentLi.classList.remove(classname);
      } else {
        parentLi.classList.add(classname);
        setTimeout(function() {
          let elHeight = parentLi.offsetHeight;
          parentLi.style.height = elHeight + 'px';
        },10)
      }

    });

  }

}
