/**
 * hamburgah killah!
 *
 *
 *
 * Created by MKR 02/01/2018
 * (c) Fabrique - Merken, Design & Interactie
 */

export default class BodyMenuController {

  constructor(element) {
    this.element = element;
    const hamburgerMenu = document.querySelector('[data-role="hamburger-menu"]');

    this.element.addEventListener('click', (event) => {
      if (this.element.classList.contains('navigation-active')) {
        //deactivate menu, otherwise do nothing
        this.element.classList.remove('navigation-active');
        hamburgerMenu.classList.remove('active');
      }
    });

  }

}
