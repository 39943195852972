/**
 * Created by LMO on 2019-02-06.
 * (c) Fabrique - Merken, Design & Interactie
 */


export default class WebcamImages {
  constructor(el) {
    this.el = el;
    this.images = Array.from(this.el.querySelectorAll('img'));
    this.urls = this.images.map(image => image.src);
    this.loading = this.images.map(_ => false);
    let iteration = 0;

    setInterval(() => {
      this.images.forEach((image, index) => {
        if (this.loading[index]) {
          return;
        }

        const img = new Image();

        const processLoadedImage = () => {
          this.loading[index] = false;
          const element = this.images[index].cloneNode();
          element.src = img.src;
          this.el.insertBefore(element, this.images[index]);
          this.el.removeChild(this.images[index]);
          this.images[index] = element;
        };

        const processFailedImage = () => {
          this.loading[index] = false;
        };

        this.loading[index] = true;
        img.src = `${this.urls[index]}?i=${iteration += 1}`;
        img.addEventListener('load', processLoadedImage);
        img.addEventListener('error', processFailedImage);
      });
    }, 4900);
  }
}
