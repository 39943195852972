/**
 * listener on a the category choices, then scroll once chosen
 *
 *
 *
 * Created by MKR 029/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */


export default class ChoiceController {

  constructor(element) {


    this.cat_selector = document.querySelectorAll('input[name="category"]');

    //loop over all choices
    [...this.cat_selector].forEach((el) => {

      el.addEventListener('click', (event) => {
        element.submit(); //submit the form
        // document.querySelector('[data-role="submit-trigger"]').scrollIntoView({ behavior: 'smooth' });
      });

    });

  }

}
