/**
 * listener on a license plate input
 *
 * sorry for jquery, but ajax requests with vanillaJS is meh
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */

import $ from 'jquery';

export default class EmailOptionsController {

  constructor(element) {
    this.element = element; // the div container
    let link = document.querySelector('[data-role="'+this.element.getAttribute("data-role") + '"] > a');
    let toggleoption = this.element.getAttribute("data-toggle");
    let box = document.querySelector('[data-role="'+this.element.getAttribute("data-role") + '"] > a > input');

    link.addEventListener('click', (event) => {

      $.ajax({
          type: "GET",
          url: "/api/toggle_email_options/?toggle="+toggleoption,
          success: function(response) {
            if (response.status === 'success') {  // texel plate TRUE, no days selector, same fee applies
              //toggle the checkbox
              $(box).prop( "checked", !$(box).prop( "checked") );
            }
            //oops error so dont do anything
          },
          error: function(jqXHR, textStatus, errorThrown) {
            console.log('ERROR')
          },
      });

    });

  }

}
