import $ from 'jquery';

/* Todo  this can be done with `use` which may be cleaner */


export default class DruktekalenderController {


  constructor(element) {
    this.element = element;

    const nextButton = document.querySelector('[data-role="druktekalender-next"]');
    const prevButton = document.querySelector('[data-role="druktekalender-previous"]');
    const nowButton = document.querySelector('[data-role="druktekalender-thisweek"]');

    nextButton.addEventListener('click', (event) => {
      event.preventDefault();
      let datum = document.getElementById('chosendate_druktekalender').value;
      this.initNewCalendar(datum,'forward');
    });
    prevButton.addEventListener('click', (event) => {
      event.preventDefault();
      let datum = document.getElementById('chosendate_druktekalender').value;
      this.initNewCalendar(datum,'backward');
    });
    nowButton.addEventListener('click', (event) => {
      event.preventDefault();
      let datum = document.getElementById('today_date').value; // this is nasty,. but it works haha
      this.initNewCalendar(datum,'static');
    });

  }


  initNewCalendar(datestr, direction) {

    let language = document.getElementById('current_language_druktekalender').value;

    let givenyear = datestr.split('-')[2];
    let givenmonth = datestr.split('-')[1];
    let givenday = datestr.split('-')[0];


    var firstDay = new Date(givenyear + '/' + givenmonth + '/' + givenday);
    if (direction == 'backward') {
      var nextWeek = new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000);
    } else if (direction == 'static') {
      var nextWeek = new Date(firstDay.getTime());
    } else {
      var nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);
    }

    var month = parseInt(nextWeek.getMonth()) + 1;
    var year = nextWeek.getFullYear();
    var dateofweek = nextWeek.getDate();

    $.ajax({
        type: "GET",
        url: "/departure_times_api/get_druktekalender/"+year+"/"+month+"/"+dateofweek+'?language='+language,
        success: function(response) {
          $('[data-role="date_schedule_container"]').html(response.content);
          document.getElementById('chosendate_druktekalender').value = dateofweek + '-' +month+ '-' + year;
        },
        error: function(jqXHR, textStatus, errorThrown) {
          console.log('ERROR')
        },
    });

    //
    // let todaybutton = document.querySelector('[data-role="date-picker-today"]');
    // let tomorrowbutton = document.querySelector('[data-role="date-picker-tomorrow"]');
    // todaybutton.classList.remove('selected');
    // tomorrowbutton.classList.remove('selected');

  };


}
