/**
 * listener on a license plate input
 *
 * sorry for jquery, but ajax requests with vanillaJS is meh
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */

import $ from 'jquery';

export default class CarUpgradeStep2Controller {

  constructor(element) {
    this.element = element; // the form

    this.texel_plate = false; // make it possible to also order without lplate
    this.day_selector = document.querySelectorAll('input[name="departure_day"]');
    this.total_fee_element = document.querySelector('[data-role="total-fee"]');

    const ticket_selector = document.querySelectorAll('input[name="product"]');
    const departure_days_container = document.querySelector('[data-role="departure_days"]');
    const license_selector = document.querySelector('select[name="license_plate"]');
    const license_input = document.querySelector('input[name="license_plate"]');

    //onload do the check for plate as well
    // this.getLicensePlateState(license_selector[license_selector.selectedIndex].value);

  if(license_selector) {

    this.getLicensePlateState(license_selector[license_selector.selectedIndex].value);
    license_selector.addEventListener('change', (event) => {
      this.license_plate = event.target.value;

      departure_days_container.classList.remove('active');
      [...this.day_selector].forEach((el) => {  //clear the options as well for sanity's sake
        el.checked = false;
      });
      [...ticket_selector].forEach((el) => {
        el.checked = false;
      });
      this.getLicensePlateState(event.target.value);
      this.resetPrice();
    });
  }
  else if(license_input) {
      license_input.addEventListener('input', (event) => {
      event.preventDefault();
      event.target.value = event.target.value.replace(/[^0-9a-z\-]/gi, '');

      if (event.target.value.length < 5) {
        return false;
      }

      let ivalue = event.target.value;
      ivalue = ivalue.replace(/-/g,"").replace(/ /g,"");
      this.getLicensePlateState(ivalue);
    });
  }

    //loop over all options
    [...ticket_selector].forEach((el) => {

      el.addEventListener('click', (event) => {
        let productid = event.target.value;

        let product = this.getObjectByValue(productPriceMap, 'id', productid);
        let amount = product[0]['amount'];
        this.chosen_product_amount = amount; // for reference
        if (amount == 1 && !this.texel_plate) {
          departure_days_container.classList.add('active');
          //deselect all options, for price calculation and forcing a choice
          [...this.day_selector].forEach((el) => {
            el.checked = false;
          });
          this.resetPrice();
        } else {
          departure_days_container.classList.remove('active');
          this.updateTotalPrice(this.texel_plate, event.target.value);
          [...this.day_selector].forEach((el) => {  //clear the options as well for sanity's sake
            el.checked = false;
          });
        }
      });

    });

    //loop over all days
    [...this.day_selector].forEach((el) => {

      el.addEventListener('click', (event) => {
        this.updateTotalPrice(false, event.target.value, 'value');
      });

    });

  }

  getObjectByValue(array, key, value) {
    return array.filter(function (object) {
        return object[key] === value;
    });
  }

  resetPrice() {
    this.total_fee_element.innerHTML = '-';
  }

  updateTotalPrice(texel_plate, chosen_value, prop='id') {

      let total_fee = parseFloat(this.total_fee_element.getAttribute('data-total-initial'));

      productPriceMap.forEach((listitem)=> {

        if (texel_plate === true) {
          if (listitem['texel_plate'] === true && listitem['amount'] === this.chosen_product_amount) {
            let price = listitem['price'];
            total_fee = price;
          }

        } else {
          if (listitem[prop] === chosen_value && listitem['amount'] === this.chosen_product_amount && listitem['texel_plate'] === false) {
            let price = listitem['price'];
            total_fee = price;
          }

        }

      });

      this.total_fee_element.innerHTML = parseFloat(total_fee).toFixed(2).replace('.', ','); + ' euro';
  }

  getLicensePlateState(platenr) {

    // const departure_days_container = document.querySelector('[data-role="departure_days"]');
    let self = this;

    $.ajax({
        type: "GET",
        url: "/api/check_license_plate_state/"+platenr+"/",
        success: function(response) {

          if (response.status === true) {  // texel plate TRUE, no days selector, same fee applies
            // departure_days_container.classList.remove('active');
            self.texel_plate = true;
          } else {
            self.texel_plate = false;
          }

        },
        error: function(jqXHR, textStatus, errorThrown) {
          console.log('ERROR')
        },
    });

  }



}
