import flatpickr from 'flatpickr';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { German } from 'flatpickr/dist/l10n/de';
import { english } from 'flatpickr/dist/l10n/default';
import $ from 'jquery';

/* Todo  this can be done with `use` which may be cleaner */

const nextIcon =
`<svg width="5" height="11" xmlns="http://www.w3.org/2000/svg">
  <path d="M.04496 3.13502L.06262.31543 5 5.28392l-5 5.03151.01788-2.85534 2.16253-2.17617z" fill-rule="nonzero" />
</svg>`;

const prevIcon =
`<svg width="5" height="11" xmlns="http://www.w3.org/2000/svg">
  <path fill="#000" fill-rule="nonzero" d="M4.95504 7.86498l-.01766 2.81959L0 5.71608 5 .68457l-.01788 2.85534-2.16253 2.17617z"/>
</svg>
`;

export default class DateRangepickerController {

  constructor(element) {
    this.element = element;
    let language = document.querySelector('[data-role="date-picker-language"]').value;
    if (language == 'nl') {
      this.loadPicker(Dutch);
    }
    else if (language == 'de') {
      this.loadPicker(German);
    }
    else {
      this.loadPicker(english);
    }
  }

  resetHighlights(button) {
    //no active colors only focus, so do it manually
    let buttons = document.querySelectorAll('.date_block_form--button--focus');

    [...buttons].forEach((el) => {
      el.classList.remove('selected');
    });
    button.classList.add('selected');

  }

  loadPicker(locale) {

    flatpickr(this.element, {
      locale: locale,
      dateFormat: 'd-m-Y',
      nextArrow: nextIcon,
      prevArrow: prevIcon,
      onChange: this.initNewDate,
      onValueUpdate: this.buttonSelect
    });
  };

  buttonSelect() {
    //dirty i know, somehow no this context when called as callback. JS logic sigh
    let buttons = document.querySelectorAll('.date_block_form--button--focus');

    [...buttons].forEach((el) => {
      el.classList.remove('selected');
    });
    this.input.classList.add('selected');
  };
}
