import './stylesheets/screen.scss';
import { install } from './scripts/libs/polyfills';
import initializeComponents from './scripts/libs/component-loader';
import smoothscroll from 'smoothscroll-polyfill';
// eslint-disable-next-line no-unused-vars
const DEBUG = (!process.env.NODE_ENV !== 'production');

if (document.documentMode) {
  console.log('Hello, Internet Explorer.');
  install();
}


// kick off the polyfill!
smoothscroll.polyfill();

// Enable hover(), focus() and hover-and-focus() mixins
document.body.classList.add(('ontouchstart' in window) ? 'touch' : 'no-touch');

document.addEventListener('DOMContentLoaded', (event) => {
  initializeComponents(event.target);
});

