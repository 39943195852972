function install() {
  // IE11 is a pretty okay browser, just these things
  Array.from = function (object) {
    'use strict';
    return [].slice.call(object);
  };
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };

  let origToggle = DOMTokenList.prototype.toggle;

  function betterToggle(val, force) {
    if (force === true) {
      this.add(val);
    } else if (force === false) {
      this.remove(val);
    } else {
      return origToggle.call(this, val);
    }
    return this.contains(val);
  }

  DOMTokenList.prototype.toggle = betterToggle;
}

export {install as default, install};
