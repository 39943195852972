/**
 * share popups
 *
 * Created by MKR 02/01/2018
 * (c) Fabrique - Merken, Design & Interactie
 */

export default class SharebutonController {
  constructor(element) {
    this.element = element;

    // const shareElement = element.querySelector('[data-role="share-button"]');
    let sharehref = this.element.href;

    this.element.addEventListener('click', (event) => {
      event.preventDefault();

      var width = 600;//$link.data('popup-width');
      var height = 360;//$link.data('popup-height');
      var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
      //Allow for title and status bars.
      var topPosition = (window.screen.height / 2) - ((height / 2) + 50);
      var windowFeatures = "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
      window.open(sharehref,'sharer', windowFeatures);

    });
  }

  loadIframe() {
    const videoType = this.frameElement.getAttribute('data-type');

    if (videoType === 'vimeo') {
      insertScript('vimeo-api-script', 'https://player.vimeo.com/api/player.js', () => {
        const iframe = document.createElement('iframe');
        iframe.id = this.id;
        iframe.className = 'video-block__player is-hidden';
        this.parentElement.replaceChild(iframe, this.frameElement);
        this.frameElement = iframe;

        this.prepareVimeo();
      });
    } else {
      insertScript('youtube-api-script', 'https://youtube.com/iframe_api', () => {
        const div = document.createElement('div');

        div.id = this.id;
        div.className = 'video-block__player is-hidden';
        this.parentElement.replaceChild(div, this.frameElement);
        this.frameElement = div;

        this.prepareYouTube();
      });
    }
  }
}
