/**
 * listener on a tooltip anchor
 *
 *
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */



const breakpointMobile = 530;
const breakpointPortrait = 800;
const breakpointLandscape = 1180;
const breakpointDesktop = 1480;

const factorMobile = 0.05;
const factorPortrait = 0.2;
const factorLandscape = 0.3;
const factorNotebook = 0.4;
const factorDesktop = 0.4;


export default class TooltipController {

  constructor(element) {
    this.element = element; // the tooltip

    this.init();

  }

  init() {

    let element = this.element;
    if (!element) {
      return;
    }

    if (document.body.classList.contains('no-touch')) {
      element.addEventListener('mousemove', () => this.popupMove(element), false);
      element.addEventListener('mouseleave', () => this.popupLeave(element), false);
      element.addEventListener('focus', () => this.popupFocus(element), false);
      element.addEventListener('blur', () => this.popupBlur(element), false);
    } else {
      element.addEventListener('click', (event) => this.popupClick(event,element), false);
      element.addEventListener('tap', (event) => this.popupClick(event,element), false);

      const closeButton = element.querySelector('.e-close');

      closeButton.addEventListener('click', (event) => this.popupClose(event,element), false);
      closeButton.addEventListener('tap', (event) =>this.popupClose(event,element), false);
    }

    const text = element.querySelector('.e-text');

    text.addEventListener('click', (event) => event.stopPropagation(), false);
    text.addEventListener('tap', (event) => event.stopPropagation(), false);

    // window.addEventListener('scroll', scrollHandler, false);
    // window.addEventListener('resize', processHorizontalPopupAlignments, false);

    this.processHorizontalPopupAlignments();

  }

   getRectHorizontalScreenPosition(rect) {

     if (
         (window.innerWidth < breakpointMobile && rect.left <= window.innerWidth * factorMobile) ||
         (window.innerWidth >= breakpointMobile && window.innerWidth < breakpointPortrait && rect.left <= window.innerWidth * factorPortrait) ||
         (window.innerWidth >= breakpointPortrait && window.innerWidth < breakpointLandscape && rect.left <= window.innerWidth * factorLandscape) ||
         (window.innerWidth >= breakpointLandscape && window.innerWidth < breakpointDesktop && rect.left <= window.innerWidth * factorNotebook) ||
         (window.innerWidth >= breakpointDesktop && rect.left <= window.innerWidth * factorDesktop)
     ) {
       return 'left';
     } else if (
         (window.innerWidth < breakpointMobile && rect.left + rect.width >= window.innerWidth * (1 - factorMobile)) ||
         (window.innerWidth >= breakpointMobile && window.innerWidth < breakpointPortrait && rect.left + rect.width >= window.innerWidth * (1 - factorPortrait)) ||
         (window.innerWidth >= breakpointPortrait && window.innerWidth < breakpointLandscape && rect.left + rect.width >= window.innerWidth * (1 - factorLandscape)) ||
         (window.innerWidth >= breakpointLandscape && window.innerWidth < breakpointDesktop && rect.left + rect.width >= window.innerWidth * (1 - factorNotebook)) ||
         (window.innerWidth >= breakpointDesktop && rect.left + rect.width >= window.innerWidth * (1 - factorDesktop))
     ) {
       return 'right';
     }

     return 'center';

   }

  processHorizontalPopupAlignments() {

    let element = this.element;

    const text = element.querySelector('.e-text');

    if (!text) {
      return;
    }

    if (element.classList.contains('is-forced-align')) {
      return;
    }

    const rect = text.getBoundingClientRect();

    // Also check for rect.width -- rect can be 0 in IE10, default to center in that case
    if (!rect.width) {
      element.classList.remove('is-align-left');
      element.classList.remove('is-align-right');

      return;
    }

    const rectHorizontalScreenPosition = this.getRectHorizontalScreenPosition(rect);

    if (rectHorizontalScreenPosition === 'left') {
      element.classList.add('is-align-left');
      element.classList.remove('is-align-right');
    } else if (rectHorizontalScreenPosition === 'right') {
      element.classList.remove('is-align-left');
      element.classList.add('is-align-right');
    } else {
      element.classList.remove('is-align-left');
      element.classList.remove('is-align-right');
    }

  }

  repositionPopupVertically(element) {
    const text = element.querySelector('.e-text');

    if (!text) {
      return;
    }

    const rect = text.getBoundingClientRect();

    if (!element.classList.contains('is-below') && rect.top <= 0) {
      element.classList.add('is-below');
    } else if (!element.classList.contains('is-above') && rect.top >= (window.innerHeight - text.offsetHeight)) {
      element.classList.remove('is-below');
    }

  }


  popupMove(element) {

    if (!element.classList.contains('has-click') && !element.classList.contains('has-focus')) {
      this.repositionPopupVertically(element);
      element.classList.add('has-hover');
    }

  }

  popupLeave(element) {

    if (element.classList.contains('has-hover')) {
      element.classList.remove('has-hover');
      this.repositionPopupVertically(element);
    }

  }

  popupFocus(element) {

    this.repositionPopupVertically(element);
    element.classList.remove('has-click');
    element.classList.add('has-focus');

  }

  popupBlur(element) {

    if (element.classList.contains('has-focus')) {
      element.classList.remove('has-focus');
      this.repositionPopupVertically(element);
    }

  }

  popupClick(event, element) {
    event.preventDefault();
    element.classList.remove('has-hover');

    if (element.classList.contains('has-click')) {
      element.classList.remove('has-click');
      this.repositionPopupVertically(element);
    } else {
      this.repositionPopupVertically(element);
      element.classList.add('has-click');
    }

  }

  popupClose(event, element) {
    event.preventDefault();

    element.classList.remove('has-hover');
    element.classList.remove('has-click');
    this.repositionPopupVertically(element);

  }



}
