import SmoothScroll from 'smooth-scroll';

export default class ScrollController {

  constructor(element) {
    this.element = element;
    this.header = this.element.closest('.page-header');
    this.scrollButton = document.querySelector('[data-role="scroll"]');
    this.departuresInfo = document.querySelector('[data-role="home-departures-info"]');
    this.homeVideoButtons = document.querySelector('[data-role="home-video-buttons"]');
    this.naivgation = document.querySelector('nav');
    if (!this.homeVideoButtons) {
      this.scrollButton.addEventListener('click', () => {
        this.scrollButton.classList.add('scroll-element--clicked');
        const scroll = new SmoothScroll();
        const secondHomeBlock = document.querySelectorAll('.home-block')[1];
        if (secondHomeBlock) {
          let heightToScroll = Math.max(285, secondHomeBlock.getBoundingClientRect().height * 0.9);
          scroll.animateScroll(heightToScroll, null, {
            speed: 1000,
            speedAsDuration: true,
            easing: 'easeInOutCubic',
          });
        }
      });
      if (window.innerWidth >= 1024) {
        this.setHeaderHeight();
        window.addEventListener('resize', () => {
          this.setHeaderHeight();
        });
      }
    }
  }

  setHeaderHeight() {
    const newHeight = window.innerHeight - (this.departuresInfo.offsetHeight + this.naivgation.offsetHeight);
    this.header.style.height = `${newHeight}px`;
  }

}

