export default class CarStep2Controller {
  constructor() {
    this.licenseInput = document.querySelector('input[name="license_plate"]');
    this.licenseCheckboxLabel = document.querySelector('label[for="id_unknown_license_plate"]');
    this.licenseCheckboxInput = document.querySelector('input[name="unknown_license_plate"]');

    this.departureDays = document.querySelectorAll('input[name="departure_day"]');

    this.total = document.querySelector('[data-role="total-fee"]');
    this.initialTotal = parseFloat(this.total.getAttribute('data-total-initial'));

    this.addEventListeners();
  }

  addEventListeners() {
    this.licenseInput.addEventListener('input', (event) => {
      event.preventDefault();

      const licensePlate = this.cleanLicensePlate(event.target.value);

      // Only check the license plate if it has more than 4 characters.
      // Some German and Belgian plates have 5 characters.
      if (licensePlate.length < 5) {
        this.licenseCheckboxLabel.classList.remove('teso-checkbox--disbaled');
        return false;
      }

      this.licenseCheckboxInput.checked = false;
      this.licenseCheckboxLabel.classList.add('teso-checkbox--disbaled');

      this.checkLicensePlateState(licensePlate);
    });

    this.licenseCheckboxLabel.addEventListener('click', () => {
      this.checkLicensePlateState('123456');
    })

    for (let day of this.departureDays) {
      day.addEventListener('click', (event) => {
        this.calculatePrice(event.target.value);
      });
    }
  }

  calculatePrice(departureDay = null) {
    let total = this.initialTotal;

    for (let productPrice of productPriceMap) {
      if (this.texelPlate) {
        if (productPrice['texel_plate'] == this.texelPlate) {
          total += productPrice['price'];
        }
      } else {
        if (!departureDay) {
          return;
        }

        if (productPrice['texel_plate'] == this.texelPlate && productPrice['value'] === departureDay) {
          total += productPrice['price'];
        }
      }
    }

    this.setTotalPrice(total);
  }

  cleanLicensePlate(licensePlate) {
    const value = licensePlate.replace(/[^\p{Letter}\p{Mark}\p{Number}\-]/giu, '');
    return value.replace(/-/g,"").replace(/ /g,"");
  }

  setTotalPrice(total) {
    this.total.innerHTML = parseFloat(total).toFixed(2).replace('.', ','); + ' euro';
  }

  resetPrice() {
    this.total.innerHTML = '-';
  }

  deselectDepartureDays() {
    for (let day of this.departureDays) {
      day.checked = false;
    }
  }

  checkLicensePlateState(licensePlate) {
    let url = `/api/check_license_plate_state/${licensePlate}/`;
    let departureContainer = document.querySelector('[data-role="departure_days"]');

    this.makeRequest(url, 'GET')
    .then(response => {
      if (response.status === true) {
        // Texel Plate is True, so you don't select a departure day as the same fee applies
        departureContainer.classList.remove('active');
        this.texelPlate = true;

        this.calculatePrice();
      } else {
        // Texel Plate is False, so you have to select a departure day
        departureContainer.classList.add('active');
        this.texelPlate = false;

        this.deselectDepartureDays();
        this.resetPrice();
      }
    })
    .catch(error => {
      console.log("ERROR")
      console.log(error)
    })
  }

  async makeRequest(url, method) {
    let options = {
      method: method,
      credentials: "same-origin",
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json'
    }

    let response = await fetch(url, options);
    return response.json();
  }
}
