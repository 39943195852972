/**
 * listener on an input to calculate total price
 *
 *
 *
 * Created by MKR 04/04/2018
 * (c) Fabrique - Merken, Design & Interactie
 */


export default class PedestrianUpgradeStep2Controller {

  constructor(element) {
    this.element = element; // the form

    this.total_fee_element = document.querySelector('[data-role="total-fee"]');
    const ticket_selector = document.querySelectorAll('input[name="product"]');

    //loop over all options
    [...ticket_selector].forEach((el) => {

      el.addEventListener('click', (event) => {
        this.updateTotalPrice(false, event.target.value);
      });

    });

  }

  updateTotalPrice(texel_plate, chosen_value) {

      let total_fee = parseFloat(this.total_fee_element.getAttribute('data-total-initial'));

      productPriceMap.forEach((listitem)=> {

        if (texel_plate === true) {

          if (listitem['texel_plate'] === true) {
            let price = listitem['price'];
            total_fee = price;
          }

        } else {
          if (listitem['value'] === chosen_value) {
            let price = listitem['price'];
            total_fee = price;
          }

        }

      });

      this.total_fee_element.innerHTML = parseFloat(total_fee).toFixed(2).replace('.', ','); + ' euro';
  }

}
