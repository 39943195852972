export default class FilterBarController {

    toggleFilterOptions() {
        const element = document.querySelector('.filter-wrapper');
        const closeButton = document.querySelector('.toggle-active');
        const showFilterButton = document.querySelector('.toggle-inactive');

        if (element && closeButton && showFilterButton) {
            // filterbar is visible, we are removing it
            if (element.classList.contains('is-visible')) {
                element.classList.remove('is-visible');
                element.classList.add('is-hidden');
                closeButton.classList.add('is-hidden');
                showFilterButton.classList.remove('is-hidden');
            }
            // filterbar is hidden, we are showing it
            else {
                element.classList.add('is-visible');
                element.classList.remove('is-hidden');
                closeButton.classList.remove('is-hidden');
                showFilterButton.classList.add('is-hidden');
            }
        }
    }

    togglePasses() {
        const element = document.querySelector('.passes-long-list');
        const showMore = document.querySelector('.toggle-inactive-passes');
        const showLess = document.querySelector('.toggle-active-passes');

        if (element && showMore && showLess) {
            // was collapsed, we are opening
            if (element.classList.contains('is-hidden')) {
                element.classList.remove('is-hidden');
                showMore.classList.add('is-hidden');
                showLess.classList.remove('is-hidden');
            }
            // was open, we are collapsing
            else {
                element.classList.add('is-hidden');
                showMore.classList.remove('is-hidden');
                showLess.classList.add('is-hidden');
            }
        }
    }

    resetFilters() {
        const form = document.getElementById("filter_form");
        if (form) {
            form.reset();
        }
        window.location = window.location.pathname;
    }

    constructor(element) {
        if (element) {
            element.querySelector('#id_toggle').addEventListener('change', () => this.toggleFilterOptions());
            element.querySelector('#id_reset_filters').addEventListener('click', () => this.resetFilters());

            let togglePasses = element.querySelector('#id_toggle_passes');
            if (togglePasses) {
                togglePasses.addEventListener('change', () => this.togglePasses());
            }
        }
    }
}
