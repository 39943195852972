export default class BicycleStep2Controller {
  constructor() {
    this.selectExtraPassenger = document.querySelector('.extra-field-activator');
    this.extraPassenger = document.querySelector('.toggle_second');

    this.numberInputs = document.querySelectorAll('input[type="number"]');

    this.total = document.querySelector('[data-role="total-fee"]');
    this.initialTotal = parseFloat(this.total.getAttribute('data-total-initial'));

    this.errorMessage = document.querySelector('.maximum-order-error');

    this.params = new URLSearchParams(document.location.search);
    this.editModus = this.params.get("edit") === 'true';

    this.addEventListeners();
    this.calculatePrice();
  }

  addEventListeners() {
    this.selectExtraPassenger.addEventListener('click', (event) => {
      this.selectExtraPassenger.classList.add('hidden');
      this.extraPassenger.classList.add('active');
    });

    for (let input of this.numberInputs) {
      input.addEventListener('input', (event) => {
        event.preventDefault();

        var value = parseInt(event.target.value)
        var count = this.params.get("count") ? parseInt(this.params.get("count")) : 0;
        var maxAmount = 100 - totalBasketAmount + count;
        var showErrorMessage = false;

        if (value > 100) {
          event.target.value = "100";
        }

        if (this.editModus && value > maxAmount) {
          // put the amount in the event target value that's left to 100
          event.target.value = `${maxAmount}`;
        }

        if (this.editModus) {
          showErrorMessage = (value > maxAmount || value > 100);
        } else {
          showErrorMessage = (value > 100 || totalBasketAmount + value > 100);
        }

        showErrorMessage ? this.errorMessage.classList.remove('hidden') : this.errorMessage.classList.add('hidden');

        this.calculatePrice();
      });
    }
  }

  calculatePrice() {
    let total = this.initialTotal;

    for (let input of this.numberInputs) {
      let name = input.name;
      let count = input.value;

      for (let productPrice of productPriceMap) {
        if (productPrice['id'] === name) {
          let price = productPrice['price'];
          total += price * count;
        }
      }
    }

    this.setTotalPrice(total);
  }

  setTotalPrice(total) {
    this.total.innerHTML = parseFloat(total).toFixed(2).replace('.', ','); + ' euro';
  }
}
